/* Contenedor general */
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f4f4f4;
  }
  
  /* Caja de inicio de sesión */
  .login-box {
    width: 300px;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 0 10px rgb(97, 31, 42);
    border-radius: 8px;
  }
  
  .login-box h2 {
    margin-bottom: 20px;
    font-weight: bold;
    text-align: center;
  }
  
  /* Grupos de input */
  .input-group {
    margin-bottom: 20px;
  }
  
  .input-group label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
  }
  
  .input-group input {
    width: calc(100% - 10px);
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  /* Botón de inicio de sesión */
  .login-box button {
    width: 100%;
    padding: 10px 15px;
    background-color: rgb(97, 31, 42);
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .login-box button:hover {
    background-color: #555;
  }
  
.categories {
    display: flex;
    justify-content: space-between;
    padding: 2rem;
  }
  
  .categories-list table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .categories-list table, .categories-list th, .categories-list td {
    border: 1px solid black;
    padding: 0.5rem;
    text-align: left;
  }
  
  .categories img {
    border-radius: 50%;
  }
  
  .categories form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .categories input {
    width: 100%;
    padding: 0.75rem 1rem;
    margin-bottom: 1rem;
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 5px;
    font-size: 1rem;
    background-color: #fff;
    transition: border-color 0.3s;
  }
  
  .categories input:focus {
    outline: none;
    border-color: rgb(97, 31, 42);
  }
  
  .categories button {
    cursor: pointer;
    padding: 0.75rem 1.5rem;
    background-color: rgb(97, 31, 42);
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    transition: background-color 0.3s;
  }
  
  .categories button:hover {
    background-color: rgba(97, 31, 42, 0.85);
  }
  

  .categories-container {
    display: flex;
    gap: 2rem;
}

.categories-list {
    flex: 1;
    overflow: auto;
    padding: 1rem;
    border-right: 1px solid rgba(0,0,0,0.1);
}

.categories-form {
    flex: 1;
    padding: 1rem;
}

input[type="text"], input[type="file"] {
    display: block;
    padding: 10px;
    margin: 10px 0;
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}

button {
    background-color: rgb(97, 31, 42);
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: rgb(72, 23, 31);
}

.preview-image {
    display: block;
    width: 60px;
    height: 60px;
    margin: 1rem 0;
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 5px;
}

.image-input-label {
    display: inline-block;
    background-color: rgba(0,0,0,0.05);
    padding: 10px;
    border: 1px dashed #ddd;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-align: center;
    width: 100%;
    border-radius: 4px;
}

.image-input-label:hover {
    background-color: rgba(0,0,0,0.08);
}
.dots {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 0 5px;
    border: 2px solid #ccc;
    cursor: pointer;
    transition: background-color 0.3s;
    background-color: white;
  }
  
  .dot.active {
    background-color: black;
    border-color: black;
  }
@font-face {
  font-family: 'Dosis';
  src: url('./fonts/Dosis/static/Dosis-Regular.ttf') format('truetype');
}

.app-container {
  font-family: 'Dosis';
}

footer {
  text-align: center;
  padding-top: 10px;
  background-color: #611f2a;
  color: #fff;
  font-family: 'Dosis';
}
@font-face {
  font-family: 'Dosis';
  src: url('../fonts/Dosis/static/Dosis-Regular.ttf') format('truetype');
}

.recommended-container {
  text-align: left;
  text-align: center;
  max-width: 88%;
  margin: auto;
}

.recommended-title {
  font-family: 'Dosis';
  color: #611f2a;
  font-size: 40px;
  text-decoration: underline;
}

.recommemded-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.item-wrapper {
  flex: 0 0 50%;
  padding: 24px;
  height: 300px;
}

.item {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
  overflow: hidden;
  background-color: #f9f9f9;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  font-family: 'Dosis';
  margin-bottom: 20px;
  border-radius: 8px;
}

.item-image {
  width: 100%;
  height: 200px;
  background-size: cover;
  background-position: center;
  margin-bottom: 10px;
}

.item-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-grow: 1;
  padding: 0 16px 16px 16px;
}

.item-details h3 {
  font-size: 14px;
  margin-bottom: 5px;
  color: #611f2a;
  text-transform: uppercase;
  letter-spacing: .5px;
}

.item-details p {
  font-size: 12px;
  margin-bottom: 5px;
  color: #611f2a;
}
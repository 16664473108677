/* Slider.css */
.slider-pagination-wrapper {
  margin-bottom: 24px;
}

.slider {
  overflow: hidden;
  width: 100%;
  position: relative;
}

.slider-wrapper {
  display: flex;
  transition: transform 0.5s ease;
}

.slider-item {
  flex: 0 0 50%;
  box-sizing: border-box;
  padding: 20px;
  background-color: #f9f9f9;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  font-family: 'Dosis';
}

.dots {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ccc;
  margin: 0 5px;
  cursor: pointer;
}

.dot.active {
  background-color: #333;
}
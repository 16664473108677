/* Estilos base para platos */

.platos {
    display: flex;
    justify-content: space-between;
    padding: 20px;
}

.platos-list {
    width: 60%;
    margin-right: 5%;
    overflow-x: auto; /* Esto permitirá que la tabla tenga su propio desplazamiento horizontal si es necesario */
}

.platos-form {
    width: 35%;
    padding: 20px;
    border-left: 1px solid #ccc;
}

table {
    width: 100%;
    border-collapse: collapse;
}

table, th, td {
    border: 1px solid #e0e0e0;
}

th, td {
    padding: 0.5rem;
    text-align: left;
}

button {
    margin-right: 0.5rem;
    padding: 0.25rem 0.5rem;
    cursor: pointer;
}

img {
    max-width: 100px;
}

/* Estilos para el formulario */

.platos-form form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.platos-form input[type="text"], 
.platos-form textarea, 
.platos-form select {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
}

.platos-form label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.platos-form .preview-image {
    max-width: 100px;
    margin-bottom: 1rem;
}

.platos-form .preview-image img {
    max-width: 100%;
}

.platos-form button {
    background-color: rgb(97, 31, 42);
    color: white;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease;

    &:hover {
        background-color: #0056b3;
    }
}

/* Estilos para la sección de filtros */
.filter-section {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    background-color: #f4f4f4;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.filter-section input,
.filter-section select {
    flex: 1;
    padding: 8px;
    margin-right: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
}

.filter-section button {
    padding: 8px 12px;
    background-color: rgb(97, 31, 42);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.filter-section button:hover {
    background-color: #0056b3;
}

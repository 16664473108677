@font-face {
  font-family: 'Dosis';
  src: url('../fonts/Dosis/static/Dosis-Regular.ttf') format('truetype');
}

.menu-title {
  font-family: 'Dosis';
  color: #611f2a;
  font-size: 40px;
}

.menu-container {
  text-align: center;
  margin: 10px 10px;
  margin-left: 100px;
  margin-right: 100px;
}

.row {
  margin: auto;
  max-width: 86%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.menu__item {
  cursor: pointer;
  width: calc(50% - 8px);
  height: 290px;
  padding: 8px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
}

@media (max-width: 1024px) {
  .row {
    max-width: 80%;
  }

  .menu__item {
    width: calc(25% - 8px);
  }

  .menu__item--button p {
    font-size: 12px;
  }

  .menu__item--button {
    transform: translateX(-30%);
  }
}

@media (max-width: 767px) {
  .row {
    max-width: 100%;
  }

  .menu__item {
    width: 100%;
  }

  .menu__item--button p {
    font-size: 14px;
  }

  .menu__item--button {
    transform: translateX(0%);
  }
}

.img {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: transform 0.3s ease;
}

.menu__item:hover .img {
  transform: scale(1.1);
}

.menu__item--button {
  position: absolute;
  bottom: 32px;
  left: 35%; 
  margin: auto;
  background-color: rgba(205, 182, 133, 0.7);
  border: 2px solid #fff;
  border-radius: 4px;
  padding: 18px;
}

.menu__item--button p {
  font-family: 'Dosis';
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  margin: 0;
}
.dashboard {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.button-container {
  width: 100%;
  margin-bottom: 16px;
  display: flex;
  padding-top: 32px;
  justify-content: center;
}

.admin-button {
  padding: 32px;
  width: 275px;
  background-color: rgb(97, 31, 42);
  color: #ffffff;
  border: none;
  border-radius: 16px;
  cursor: pointer;
  transition: 0.3s background-color;
}

.admin-button:hover {
  background-color: #0056b3;
}

.header-container {
  background-color: rgb(97, 31, 42);
  color: #fff;
  padding: 0;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
}

.logo-container img {
  max-width: 150px;
}

.nav-container {
  list-style: none;
  margin: 0;
  padding: 0;
  border: 0;
}

.nav-container ul {
  justify-content: center !important;
  min-height: 75px;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding: 0;
}

.nav-container ul li a {
  text-decoration: none;
  color: white;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1.5px;
  height: 75px;
  padding-right: 17.5px;
  padding-left: 17.5px;
  align-items: center;
  display: flex;
}

.nav-container ul li a:hover {
  color: #e40513;
}

.nav-container li {
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
}

.nav-container li:hover {
  text-decoration: none;
}

.nav-toggle {
  display: none;
}

@media (max-width: 1024px) {

  .header-container {
    display: flex;
    flex-direction: column;
  }

  .logo-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
  }

  .nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  .nav-toggle {
    cursor: pointer;
    color: white;
    font-size: 18px;
    width: 70px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;
  }
 
  .nav-toggle:hover {
    color: #e40513;
  }

  .nav-list {
    display: flex;
    flex-direction: row;
  }

  .nav-list li{
    margin: 0 10px;
  }

  .nav-container ul {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: rgb(97, 31, 42);
    z-index: 1;
    padding: 0;
    margin: 0;
  }

  .nav-container.open ul {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .nav-container.open ul li {
    padding: 10px;
    text-align: center;
    width: 100%;
  }
}
.container{
   display: flex;
   flex-direction: column;
   min-height: 100vh;
}

.food-content {
    display: flex;
    margin: 50px 30px;
    max-width: 100%;
    flex: 1 0 auto;
}

.food-image {
    flex: 1;
    height: 400px;
    background-size: cover;
    background-position: center;
    background-color: #f9f9f9;
}

.food-details {
    flex: 1;
    padding-left: 20px;
}

.food-title {
    font-size: 16px;
    color: #611f2a;
    font-family: 'Dosis';
}

.description {
    font-size: 13px;
    color: #333;
    font-family: 'Dosis';
}

.price {
    font-size: 22px;
    line-height: 22px;
    font-weight: bold;
    color: #611f2a;
    font-family: 'Dosis';
}

@media (max-width: 768px) {
    .food-content {
        display: inline;
        flex-direction: column;
        align-items: center;
    }

    .food-image {
        width: 100%;
        height: 300px;
        background-size: cover;
        background-position: center;
    }

    .food-details {
        padding-left: 0;
        margin-top: 20px;
        text-align: center;
    }
}
/* styles.css */
.container__category {
  text-align: left;
}

.category-title {
  font-family: 'Dosis';
  text-align: center;
  color: #611f2a;
  font-size: 30px;
  text-transform: uppercase;
  margin-bottom: 70px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding-left: 30px;
  padding-right: 30px;
}

.food-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  font-family: 'Dosis';
}

.food-img {
  width: 100%;
  height: 200px;
  background-size: cover;
  background-position: center;
}

.food-info {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: left;
  height: 100%;
  align-self: flex-start;
}

.food-name {
  font-size: 14px;
  font-weight: bold;
  color: #611f2a;
  margin-bottom: 5px;
  text-align: left;
}

.food-description {
  font-size: 12px;
  color: #000;
  margin-bottom: 5px;
  text-align: left;
}

.food-price{
  font-size: 12px;
  font-weight: bold;
  color: #611f2a;
  margin: 0;
  text-align: left;
}

@media (max-width: 768px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .grid {
    grid-template-columns: repeat(1, 1fr);
  }
}